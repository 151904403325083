import React from 'react';
import classNames from 'classnames';

import css from './SimpleSection.module.css';

const SimpleSection = props => {
  const { icon, title, description, className } = props;

  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      {icon ? (
        <div className={css.leftSection}>
          <div className={css.iconWrapper}>{icon}</div>
        </div>
      ) : null}
      <div className={css.rightSection}>
        <h1 className={css.title}>{title}</h1>
        <p className={css.description}>{description}</p>
      </div>
    </div>
  );
};

export default SimpleSection;
