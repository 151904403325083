import React from 'react';

import css from './IconHeart.module.css';

const IconHeart = () => {
  return (
    <svg
      className={css.root}
      width="50"
      height="45"
      viewBox="0 0 50 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.8736 24.1951L26.2955 44.4599C25.9536 44.8137 25.5038 45 25.0179 45C24.532 45 24.0822 44.7951 23.7402 44.4599L4.1622 24.1951C-1.12819 18.7191 -1.4161 9.8345 3.53236 4.41427C6.0516 1.63906 9.47027 0.0745025 13.1417 0.0185956C16.8306 0.0185956 20.2676 1.41555 22.8766 4.09766L25 6.31415L27.1233 4.09766C29.7325 1.39696 33.2052 0 36.8582 0C40.5291 0.0558772 43.9478 1.62047 46.4676 4.39567C51.4161 9.83438 51.1282 18.7002 45.8378 24.1765L45.8736 24.1951Z"
        fill="#D2EBE6"
      />
    </svg>
  );
};

export default IconHeart;
