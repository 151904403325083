import React from 'react';

const IconFacebook = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.101 0.9 18 2 18H9V11H7V8.525H9V6.475C9 4.311 10.212 2.791 12.766 2.791L14.569 2.793V5.398H13.372C12.378 5.398 12 6.144 12 6.836V8.526H14.568L14 11H12V18H16C17.1 18 18 17.101 18 16V2C18 0.9 17.1 0 16 0Z"
        fill="#9191D6"
      />
    </svg>
  );
};

export default IconFacebook;
