import React from 'react';

import css from './PreviewIcon.module.css';

const PreviewIcon = () => {
  return (
    <svg
      className={css.root}
      width="47"
      height="46"
      viewBox="0 0 47 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0769 0H5.9231C2.6231 0 0 2.9017 0 6.4007V34.7347C0 38.2338 2.7077 41.1354 5.9231 41.1354H15.0615L19.0385 45.4879C19.3769 45.8293 19.8 46 20.3077 46C20.4769 46 20.7308 46 20.9 45.9147C21.5769 45.6586 22 45.0612 22 44.2931V6.4007C22 2.9017 19.2923 0 16.0769 0Z"
        fill="white"
      />
      <path
        d="M41.0769 0H30.9231C27.6231 0 25 2.8963 25 6.3889V44.2963C25 44.9778 25.4231 45.6593 26.1 45.9148C26.2692 46 26.5231 46 26.6923 46C27.2 46 27.6231 45.8296 27.9615 45.4889L31.9385 41.1444H41.0769C44.3769 41.1444 47 38.2481 47 34.7556V6.3889C47 2.8963 44.2923 0 41.0769 0Z"
        fill="white"
      />
    </svg>
  );
};

export default PreviewIcon;
