import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useConfiguration } from '../../context/configurationContext';
import { useIntl } from '../../util/reactIntl';
import { LinkedLogo, IconFacebook, IconInstagram, IconLinkedin, NamedLink } from '../../components';

import css from './FooterContainer.module.css';

// import { useConfiguration } from '../../context/configurationContext';
// import loadable from '@loadable/component';

// const SectionBuilder = loadable(
//   () => import(/* webpackChunkName: "SectionBuilder" */ '../PageBuilder/PageBuilder'),
//   {
//     resolveComponent: components => components.SectionBuilder,
//   }
// );

const FooterComponent = () => {
  const config = useConfiguration();
  const intl = useIntl();

  const marketplaceName = config.marketplaceName;
  // // If footer asset is not set, let's not render Footer at all.
  // if (Object.keys(footer).length === 0) {
  //   return null;
  // }

  // // The footer asset does not specify sectionId or sectionType. However, the SectionBuilder
  // // expects sectionId and sectionType in order to identify the section. We add those
  // // attributes here before passing the asset to SectionBuilder.
  // const footerSection = {
  //   ...footer,
  //   sectionId: 'footer',
  //   sectionType: 'footer',
  //   linkLogoToExternalSite: topbar?.logoLink,
  // };

  // return <SectionBuilder sections={[footerSection]} />;

  const links = [
    {
      label: intl.formatMessage({ id: 'Footer.aboutUsPage' }),
      pathName: 'AboutUsPage',
    },
    {
      label: intl.formatMessage({ id: 'Footer.termsOfServicePage' }),
      pathName: 'TermsOfServicePage',
    },
    {
      label: intl.formatMessage({ id: 'Footer.privacyPolicyPage' }),
      pathName: 'PrivacyPolicyPage',
    },
    {
      label: intl.formatMessage({ id: 'Footer.fAQPage' }),
      pathName: 'FAQPage',
    },
    {
      label: intl.formatMessage({ id: 'Footer.contactUsPage' }),
      pathName: 'ContactUsPage',
    },
  ];

  return (
    <div className={css.root}>
      <div className={css.footerContent}>
        <div className={css.logoWrapper}>
          <LinkedLogo
            className={css.logoLink}
            layout="desktop"
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
            linkToExternalSite={config?.topbar?.logoLink}
          />
        </div>
        <div className={css.linksWrapper}>
          <ul className={css.linksList}>
            {links.map(link => (
              <li key={link.pathName} className={css.linkItem}>
                <NamedLink name={link.pathName}>{link.label}</NamedLink>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <ul className={css.socialsList}>
            <li>
              <IconFacebook />
            </li>
            <li>
              <IconInstagram />
            </li>
            <li>
              <IconLinkedin />
            </li>
          </ul>
        </div>
      </div>
      <div className={css.copyrightWrapper}>
        <p className={css.copyright}>
          <FormattedMessage id="Footer.copyright" />
        </p>
      </div>
    </div>
  );
};

// NOTE: if you want to add dynamic data to FooterComponent,
//       you could just connect this FooterContainer to Redux Store
//
// const mapStateToProps = state => {
//   const { currentUser } = state.user;
//   return { currentUser };
// };
// const FooterContainer = compose(connect(mapStateToProps))(FooterComponent);
// export default FooterContainer;

export default FooterComponent;
