import React from 'react';

const IconInstagram = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.84305 0.124512C2.85669 0.124512 1.91071 0.516243 1.21311 1.21357C0.51552 1.9109 0.123426 2.85673 0.123047 3.84308V13.7631C0.123047 14.7497 0.514974 15.6959 1.21261 16.3935C1.91024 17.0912 2.85644 17.4831 3.84305 17.4831H13.763C14.7494 17.4827 15.6952 17.0906 16.3926 16.393C17.0899 15.6954 17.4816 14.7494 17.4816 13.7631V3.84308C17.4812 2.85697 17.0893 1.91136 16.3921 1.21407C15.6948 0.516789 14.7492 0.12489 13.763 0.124512H3.84305ZM14.833 3.8488C14.833 4.13296 14.7202 4.40548 14.5192 4.60641C14.3183 4.80734 14.0458 4.92023 13.7616 4.92023C13.4775 4.92023 13.2049 4.80734 13.004 4.60641C12.8031 4.40548 12.6902 4.13296 12.6902 3.8488C12.6902 3.56464 12.8031 3.29211 13.004 3.09118C13.2049 2.89025 13.4775 2.77737 13.7616 2.77737C14.0458 2.77737 14.3183 2.89025 14.5192 3.09118C14.7202 3.29211 14.833 3.56464 14.833 3.8488ZM8.80447 5.83166C8.0164 5.83166 7.26061 6.14471 6.70336 6.70197C6.14611 7.25922 5.83305 8.01501 5.83305 8.80308C5.83305 9.59116 6.14611 10.3469 6.70336 10.9042C7.26061 11.4615 8.0164 11.7745 8.80447 11.7745C9.59255 11.7745 10.3483 11.4615 10.9056 10.9042C11.4628 10.3469 11.7759 9.59116 11.7759 8.80308C11.7759 8.01501 11.4628 7.25922 10.9056 6.70197C10.3483 6.14471 9.59255 5.83166 8.80447 5.83166ZM4.40305 8.80308C4.40305 7.63613 4.86662 6.51697 5.69178 5.69181C6.51694 4.86665 7.63609 4.40308 8.80305 4.40308C9.97 4.40308 11.0892 4.86665 11.9143 5.69181C12.7395 6.51697 13.203 7.63613 13.203 8.80308C13.203 9.97004 12.7395 11.0892 11.9143 11.9144C11.0892 12.7395 9.97 13.2031 8.80305 13.2031C7.63609 13.2031 6.51694 12.7395 5.69178 11.9144C4.86662 11.0892 4.40305 9.97004 4.40305 8.80308Z"
        fill="#9191D6"
      />
    </svg>
  );
};

export default IconInstagram;
