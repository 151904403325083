import React from 'react';

const IconLinkedin = () => {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.33136 0C1.49938 0 0 1.58754 0 3.52733V14.4732C0 16.413 1.49934 18 3.33136 18H13.6692C15.5012 18 17 16.413 17 14.4732V3.52733C17 1.58757 15.5012 0 13.6692 0H3.33136ZM4.16925 2.97036C5.04765 2.97036 5.58871 3.58094 5.60541 4.38353C5.60541 5.1684 5.04762 5.79614 4.15226 5.79614H4.13578C3.2741 5.79614 2.71716 5.16844 2.71716 4.38353C2.71716 3.58095 3.29097 2.97036 4.16925 2.97036ZM11.7389 6.72158C13.4283 6.72158 14.6946 7.89069 14.6946 10.403V15.0932H12.1273V10.7175C12.1273 9.61793 11.7558 8.86776 10.8267 8.86776C10.1174 8.86776 9.69461 9.37338 9.50899 9.86183C9.44118 10.0366 9.42451 10.2806 9.42451 10.5251V15.0932H6.85719C6.85719 15.0932 6.89088 7.6809 6.85719 6.9134H9.42504V8.07174C9.76621 7.5144 10.3765 6.72158 11.7389 6.72158ZM2.86859 6.914H5.43591V15.0932H2.86859V6.914Z"
        fill="#9191D6"
      />
    </svg>
  );
};

export default IconLinkedin;
