import React from 'react';
import classNames from 'classnames';

import css from './SectionStepInfo.module.css';

const SectionStepInfo = ({ steps, title, subTitle, classNameHeadline, rootClassName }) => {
  const classesHeadline = classNames(css.headline, classNameHeadline);
  const classesRoot = classNames(css.root, rootClassName);

  return (
    <>
      <div className={classesHeadline}>
        <h1 className={css.title}>{title}</h1>
        <p className={css.subTitle}>{subTitle}</p>
      </div>
      <div className={classesRoot}>
        {steps.map(({ title, list, description }, index) => (
          <div key={title} className={css.stepItem}>
            <div className={css.circle}>{index + 1}</div>
            {index < steps.length ? <div className={css.dynamicLine} /> : null}
            <div className={css.stepInfo} data-color="bg-color">
              <h3 className={css.stepInfoTitle}>{title}</h3>
              {description ? <p>{description}</p> : null}
              {list ? (
                <ul className={css.stepInfoList}>
                  {list.map(text => (
                    <li key={text} className={css.stepInfoListItem}>
                      {text}
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default SectionStepInfo;
