import React, { Component } from 'react';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, useIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isMainSearchTypeKeywords } from '../../util/search';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { sendEmail } from '../../util/api';

import { Heading, Page, LayoutSingleColumn } from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';

import ContactForm from './ContactForm/ContactForm';

import css from './ContactUsPage.module.css';

export class ContactUsPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  render() {
    const { intl, scrollingDisabled, onManageDisableScrolling } = this.props;
    const { isLoading } = this.state;

    const title = intl.formatMessage({
      id: 'ContactForm.metaTitle',
    });

    const handleSubmit = values => {
      this.setState({ isLoading: true });

      return sendEmail(values).finally(() => this.setState({ isLoading: false }));
    };

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
          <div className={css.root}>
            <div className={css.content}>
              <Heading as="h1" rootClassName={css.heading}>
                <FormattedMessage id="ContactForm.title" />
              </Heading>
              <p className={css.description}>
                <FormattedMessage id="ContactForm.description" />
              </p>
              <ContactForm
                className={css.form}
                onSubmit={handleSubmit}
                isLoading={isLoading}
                onManageDisableScrolling={onManageDisableScrolling}
              />
            </div>
          </div>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ContactUsPageComponent.defaultProps = {
  staticContext: {},
};

ContactUsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  marketplaceName: string.isRequired,
  isKeywordSearch: bool.isRequired,

  // The context object from StaticRouter,
  // it should be manually passed if this page is rendered inside other route.
  staticContext: object,

  // from useIntl
  intl: intlShape.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const EnhancedContactUsPage = props => {
  const routeConfiguration = useRouteConfiguration();
  const config = useConfiguration();
  const history = useHistory();
  const intl = useIntl();

  return (
    <ContactUsPageComponent
      routeConfiguration={routeConfiguration}
      marketplaceName={config.marketplaceName}
      isKeywordSearch={isMainSearchTypeKeywords(config)}
      history={history}
      intl={intl}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ContactUsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedContactUsPage);

export default ContactUsPage;
