import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';

import bgImage from '../../assets/backgrounds/training-bg.png';

import css from './SectionSimpleHero.module.css';

const SectionSimpleHero = () => {
  return (
    <div className={css.root}>
      <h1 className={css.mainTitle}>
        <FormattedMessage id="SectionSimpleHero.title" />
      </h1>
      <div className={css.content}>
        <div className={css.leftSide}>
          <img className={css.image} src={bgImage} alt="SectionSimpleHero" />
        </div>
        <div className={css.rightSide}>
          <div className={classNames(css.section, css.section1)}>
            <h3 className={css.title}>
              <FormattedMessage id="SectionSimpleHero.title2" />
            </h3>
            <p className={css.description}>
              <FormattedMessage id="SectionSimpleHero.description" />
            </p>
          </div>
          <div className={classNames(css.section, css.section2)}>
            <h3 className={css.title}>
              <FormattedMessage id="SectionSimpleHero.title3" />
            </h3>
            <ul className={css.list}>
              <li className={css.listItem}>
                <FormattedMessage id="SectionSimpleHero.listItem1" />
              </li>
              <li className={css.listItem}>
                <FormattedMessage id="SectionSimpleHero.listItem2" />
              </li>
            </ul>
          </div>
          <div className={classNames(css.section, css.section3)}>
            <h3 className={css.title}>
              <FormattedMessage id="SectionSimpleHero.title4" />
            </h3>
            <ul className={css.list}>
              <li className={css.listItem}>
                <FormattedMessage id="SectionSimpleHero.listItem3" />
              </li>
              <li className={css.listItem}>
                <FormattedMessage id="SectionSimpleHero.listItem4" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionSimpleHero;
