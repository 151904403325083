import React from 'react';

import css from './PazzleIcon.module.css';

const PazzleIcon = () => {
  return (
    <svg
      className={css.root}
      width="50"
      height="49"
      viewBox="0 0 50 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.607 0C25.0888 0 21.4177 3.62573 21.4177 8.10506V9.21556H10.5604C10.3363 9.21558 10.1214 9.30459 9.96302 9.46302C9.80459 9.62145 9.71557 9.83633 9.71555 10.0604V20.9177H8.60506C4.1257 20.9177 0.5 24.5888 0.5 29.107C0.5 33.6252 4.1257 37.2962 8.60506 37.2962H9.71555V48.1552C9.71556 48.3792 9.80458 48.5941 9.96301 48.7525C10.1214 48.911 10.3363 49 10.5604 49H22.2626C22.4866 49 22.7015 48.911 22.8599 48.7525C23.0184 48.5941 23.1074 48.3792 23.1074 48.1552V46.2015C23.1074 42.6396 25.9854 39.7861 29.607 39.7861C33.1726 39.7861 36.0195 42.5485 36.1082 46.0299C36.1081 46.0341 36.1081 46.0384 36.1082 46.0427C36.1089 46.0586 36.1082 46.1151 36.1082 46.201V48.1547C36.1082 48.3788 36.1972 48.5936 36.3556 48.7521C36.5141 48.9105 36.7289 48.9995 36.953 48.9996H48.6552C48.8792 48.9995 49.0941 48.9105 49.2525 48.7521C49.411 48.5936 49.5 48.3788 49.5 48.1547V36.4525C49.4999 36.2286 49.4108 36.0138 49.2524 35.8555C49.094 35.6971 48.8792 35.6082 48.6552 35.6082H46.7015C43.1396 35.6082 40.2861 32.7302 40.2861 29.1086C40.2861 25.543 43.0486 22.6977 46.5299 22.609C46.5343 22.6085 46.5386 22.608 46.543 22.6074C46.559 22.6067 46.6158 22.609 46.7014 22.609H48.6552C48.8792 22.609 49.0941 22.52 49.2525 22.3616C49.411 22.2031 49.5 21.9883 49.5 21.7642V10.0604C49.5 9.83633 49.411 9.62145 49.2525 9.46302C49.0941 9.30459 48.8792 9.21558 48.6552 9.21556H37.7978V8.10672C37.7978 3.62761 34.1248 0.000344849 29.607 0Z"
        fill="#F8D987"
      />
    </svg>
  );
};

export default PazzleIcon;
