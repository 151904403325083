import React from 'react';

import css from './IconAdult.module.css';

const IconAdult = () => {
  return (
    <svg
      className={css.root}
      width="28"
      height="30"
      viewBox="0 0 28 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5065 0.0665285C15.4075 0.0660809 18.2096 1.12128 20.3898 3.03518C22.57 4.94909 23.9792 7.59091 24.3546 10.4676L27.7146 15.7525C27.9356 16.1005 27.8908 16.6186 27.3786 16.8397L24.4531 18.0911V22.4665C24.4531 23.2586 24.1385 24.0183 23.5784 24.5784C23.0183 25.1385 22.2586 25.4532 21.4665 25.4532H18.4813L18.4798 29.9332H5.0398V24.4168C5.0398 22.6547 4.38871 20.9866 3.1806 19.4814C1.77344 17.7244 0.891288 15.6056 0.635799 13.3691C0.38031 11.1327 0.761876 8.86952 1.73653 6.84046C2.71119 4.81139 4.23928 3.09898 6.14473 1.90051C8.05018 0.702048 10.2555 0.0663026 12.5065 0.0665285ZM12.5065 3.0532C10.8184 3.05312 9.1646 3.52994 7.73566 4.4287C6.30671 5.32747 5.16074 6.61162 4.42974 8.13323C3.69874 9.65485 3.41246 11.352 3.60388 13.0292C3.7953 14.7064 4.45663 16.2954 5.51169 17.6132C7.1454 19.6501 8.02647 21.9693 8.02647 24.4168V26.9465H15.4931L15.4961 22.4665H21.4665V16.1229L23.7811 15.1313L21.4769 11.5099L21.3918 10.8499C21.1094 8.69325 20.0522 6.71296 18.4173 5.27839C16.7824 3.84381 14.6815 3.05291 12.5065 3.0532ZM12.5065 7.5332C12.9025 7.5332 13.2824 7.69053 13.5624 7.97058C13.8425 8.25064 13.9998 8.63047 13.9998 9.02653V10.5199H15.4931C15.8892 10.5199 16.269 10.6772 16.5491 10.9573C16.8291 11.2373 16.9865 11.6171 16.9865 12.0132C16.9865 12.4093 16.8291 12.7891 16.5491 13.0691C16.269 13.3492 15.8892 13.5065 15.4931 13.5065H13.9998V14.9999C13.9998 15.3959 13.8425 15.7758 13.5624 16.0558C13.2824 16.3359 12.9025 16.4932 12.5065 16.4932C12.1104 16.4932 11.7306 16.3359 11.4505 16.0558C11.1705 15.7758 11.0131 15.3959 11.0131 14.9999V13.505L9.5198 13.5065C9.12374 13.5065 8.74391 13.3492 8.46385 13.0691C8.1838 12.7891 8.02647 12.4093 8.02647 12.0132C8.02647 11.6171 8.1838 11.2373 8.46385 10.9573C8.74391 10.6772 9.12374 10.5199 9.5198 10.5199L11.0131 10.5184V9.02653C11.0131 8.63047 11.1705 8.25064 11.4505 7.97058C11.7306 7.69053 12.1104 7.5332 12.5065 7.5332Z"
        fill="black"
      />
      <path
        d="M12.5065 0.0665285C15.4075 0.0660809 18.2096 1.12128 20.3898 3.03518C22.57 4.94909 23.9792 7.59091 24.3546 10.4676L27.7146 15.7525C27.9356 16.1005 27.8908 16.6186 27.3786 16.8397L24.4531 18.0911V22.4665C24.4531 23.2586 24.1385 24.0183 23.5784 24.5784C23.0183 25.1385 22.2586 25.4532 21.4665 25.4532H18.4813L18.4798 29.9332H5.0398V24.4168C5.0398 22.6547 4.38871 20.9866 3.1806 19.4814C1.77344 17.7244 0.891288 15.6056 0.635799 13.3691C0.38031 11.1327 0.761876 8.86952 1.73653 6.84046C2.71119 4.81139 4.23928 3.09898 6.14473 1.90051C8.05018 0.702048 10.2555 0.0663026 12.5065 0.0665285ZM12.5065 3.0532C10.8184 3.05312 9.1646 3.52994 7.73566 4.4287C6.30671 5.32747 5.16074 6.61162 4.42974 8.13323C3.69874 9.65485 3.41246 11.352 3.60388 13.0292C3.7953 14.7064 4.45663 16.2954 5.51169 17.6132C7.1454 19.6501 8.02647 21.9693 8.02647 24.4168V26.9465H15.4931L15.4961 22.4665H21.4665V16.1229L23.7811 15.1313L21.4769 11.5099L21.3918 10.8499C21.1094 8.69325 20.0522 6.71296 18.4173 5.27839C16.7824 3.84381 14.6815 3.05291 12.5065 3.0532ZM12.5065 7.5332C12.9025 7.5332 13.2824 7.69053 13.5624 7.97058C13.8425 8.25064 13.9998 8.63047 13.9998 9.02653V10.5199H15.4931C15.8892 10.5199 16.269 10.6772 16.5491 10.9573C16.8291 11.2373 16.9865 11.6171 16.9865 12.0132C16.9865 12.4093 16.8291 12.7891 16.5491 13.0691C16.269 13.3492 15.8892 13.5065 15.4931 13.5065H13.9998V14.9999C13.9998 15.3959 13.8425 15.7758 13.5624 16.0558C13.2824 16.3359 12.9025 16.4932 12.5065 16.4932C12.1104 16.4932 11.7306 16.3359 11.4505 16.0558C11.1705 15.7758 11.0131 15.3959 11.0131 14.9999V13.505L9.5198 13.5065C9.12374 13.5065 8.74391 13.3492 8.46385 13.0691C8.1838 12.7891 8.02647 12.4093 8.02647 12.0132C8.02647 11.6171 8.1838 11.2373 8.46385 10.9573C8.74391 10.6772 9.12374 10.5199 9.5198 10.5199L11.0131 10.5184V9.02653C11.0131 8.63047 11.1705 8.25064 11.4505 7.97058C11.7306 7.69053 12.1104 7.5332 12.5065 7.5332Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  );
};

export default IconAdult;
