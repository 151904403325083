import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { intlShape, injectIntl } from '../../../util/reactIntl';
import { Button, FieldTextInput, Form, Modal } from '../../../components';

import css from './ContactForm.module.css';
import { required } from '../../../util/validators';

const ContactFormComponent = props => {
  const [disableSend, setDisableSend] = useState(false);
  const [shopPopup, setShopPopup] = useState(false);

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          intl,
          handleSubmit,
          updated,
          pristine,
          ready,
          invalid,
          disabled,
          isLoading,
          form,
          values,
          onManageDisableScrolling,
        } = formRenderProps;
        const classes = classNames(rootClassName || css.root, className);

        const nameRequiredMessage = intl.formatMessage({ id: 'ContactForm.nameIsRequired' });
        const emailRequiredMessage = intl.formatMessage({ id: 'ContactForm.emailIsRequired' });
        const messageRequiredMessage = intl.formatMessage({ id: 'ContactForm.messageIsRequired' });
        const btnText = intl.formatMessage({ id: 'ContactForm.btnText' });

        const submitReady = (updated && pristine) || ready;
        const submitDisabled = invalid || disabled || disableSend;

        const onSubmit = e => {
          handleSubmit(e)
            .then(() => {
              Object.keys(values).forEach(key => {
                form.change(key, undefined);
                form.resetFieldState(key);
              });
              form.reset();
            })
            .finally(() => {
              setShopPopup(true);
              setDisableSend(true);
              setTimeout(() => {
                setDisableSend(false);
                setDisableSend(false);
              }, 60000);
            });
        };

        return (
          <>
            <Form className={classes} onSubmit={onSubmit}>
              <FieldTextInput
                id="name"
                name="name"
                inputRootClass={css.input}
                type="text"
                placeholder={intl.formatMessage({ id: 'ContactForm.namePlaceholder' })}
                validate={required(nameRequiredMessage)}
              />

              <FieldTextInput
                id="email"
                name="email"
                inputRootClass={css.input}
                type="text"
                placeholder={intl.formatMessage({ id: 'ContactForm.emailPlaceholder' })}
                validate={required(emailRequiredMessage)}
              />

              <FieldTextInput
                id="address"
                name="address"
                inputRootClass={css.input}
                type="text"
                placeholder={intl.formatMessage({ id: 'ContactForm.addressPlaceholder' })}
              />

              <FieldTextInput
                id="message"
                name="message"
                inputRootClass={css.textarea}
                type="textarea"
                placeholder={intl.formatMessage({ id: 'ContactForm.messagePlaceholder' })}
                validate={required(messageRequiredMessage)}
              />

              <Button
                className={css.button}
                type="submit"
                disabled={submitDisabled}
                ready={submitReady}
                inProgress={isLoading}
              >
                {btnText}
              </Button>
            </Form>

            <Modal
              id="successSendMessage"
              isOpen={shopPopup}
              onClose={() => setShopPopup(false)}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div>
                <p className={css.successSendMessage}>
                  {intl.formatMessage({ id: 'ContactForm.successSendMessage' })}
                </p>
              </div>
            </Modal>
          </>
        );
      }}
    />
  );
};

ContactFormComponent.defaultProps = { rootClassName: null, className: null };

ContactFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
  isKeywordSearch: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ContactForm = injectIntl(ContactFormComponent);

export default ContactForm;
