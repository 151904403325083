import React from 'react';

import { NamedLink, QuotesIcon, SimpleSlider } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import { useMediaQuery } from '../../hooks/useMediaQuery';

import people1 from '../../assets/peoples/Photo.png';
import people2 from '../../assets/peoples/Ellipse 1206.png';
import people3 from '../../assets/peoples/Ellipse 1207.png';

import css from './SectionFeedback.module.css';

const SectionFeedback = props => {
  const { intl } = props;

  const matches = useMediaQuery('(min-width: 768px)');

  const feedbacks = [
    {
      img: people1,
      fullname: intl.formatMessage({
        id: 'ShopProductsAndResources.feedbackTitle1',
      }),
      description: intl.formatMessage(
        {
          id: 'ShopProductsAndResources.feedbackDescription1',
        },
        { br: <br /> }
      ),
    },
    {
      img: people3,
      fullname: intl.formatMessage({
        id: 'ShopProductsAndResources.feedbackTitle2',
      }),
      description: intl.formatMessage(
        {
          id: 'ShopProductsAndResources.feedbackDescription2',
        },
        { br: <br /> }
      ),
    },
    {
      img: people2,
      fullname: intl.formatMessage({
        id: 'ShopProductsAndResources.feedbackTitle3',
      }),
      description: intl.formatMessage(
        {
          id: 'ShopProductsAndResources.feedbackDescription3',
        },
        { br: <br /> }
      ),
    },
  ];

  return (
    <div className={css.root}>
      <h1 className={css.title}>
        <FormattedMessage id="ShopProductsAndResources.feedbackSectionTitle" />
      </h1>
      <div className={css.iconWrapper}>
        <QuotesIcon />
      </div>
      <div className={css.feedbacksWrapper}>
        {matches ? (
          <ul className={css.list}>
            {feedbacks.map(({ img, fullname, description }) => {
              return (
                <li className={css.listItem} key={fullname}>
                  <img src={img} alt="people" />
                  <span className={css.fullname}>{fullname}</span>
                  <p className={css.description}>{description}</p>
                </li>
              );
            })}
          </ul>
        ) : (
          <SimpleSlider blocks={feedbacks} />
        )}
      </div>
      <div className={css.btnWrapper}>
        <NamedLink name="ShopProductsAndResources" className={css.btn}>
          <FormattedMessage id="ShopProductsAndResources.showMoreFeedback" />
        </NamedLink>
      </div>
    </div>
  );
};

export default SectionFeedback;
