import React from 'react';
import classNames from 'classnames';

import css from './SectionSimpleDescription.module.css';

const SectionSimpleDescription = props => {
  const { description, className } = props;

  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <p className={css.description}>{description}</p>
    </div>
  );
};

export default SectionSimpleDescription;
