import React from 'react';

const IconMenu = () => {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 0.833313H22.5M1.5 8.99998H22.5M1.5 17.1666H22.5"
        stroke="#9191D6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMenu;
