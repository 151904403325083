import React, { useState } from 'react';

import { IconArrowLeft, IconArrowRight } from '../../components';

import css from './SimpleSlider.module.css';

const SimpleSlider = props => {
  const { blocks } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex(prevIndex => (prevIndex === 0 ? blocks.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex => (prevIndex === blocks.length - 1 ? 0 : prevIndex + 1));
  };

  const { img, fullname, description } = blocks[currentIndex] ?? {};

  return (
    <div className={css.root}>
      <button className={css.button} onClick={handlePrev}>
        <IconArrowLeft />
      </button>
      <div className={css.block}>
        <img src={img} alt="people" />
        <span className={css.fullname}>{fullname}</span>
        <p className={css.description}>{description}</p>
      </div>
      <button className={css.button} onClick={handleNext}>
        <IconArrowRight />
      </button>
    </div>
  );
};

export default SimpleSlider;
