import React from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { NamedLink } from '../../components';

import css from './Breadcrumbs.module.css';

const Breadcrumbs = ({ items, separator = '>' }) => {
  const location = useLocation();

  return (
    <nav aria-label="breadcrumb" className={css.root}>
      <ol className={css.breadcrumbs}>
        {items.map((item, index) => {
          const isActive = location.pathname === item.link;

          return (
            <li
              key={item.name}
              className={classNames(css.breadcrumbItem, { [css.active]: isActive })}
              aria-current={isActive ? 'page' : undefined}
            >
              {index !== 0 && <span className={css.separator}>{separator}</span>}
              {item.name ? (
                <NamedLink name={item.name}>{item.label}</NamedLink>
              ) : (
                <span>{item.label}</span>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
