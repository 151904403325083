import React from 'react';

const QuotesIcon = () => {
  return (
    <svg width="65" height="44" viewBox="0 0 65 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M50 28C47.2311 28 44.5243 27.1789 42.222 25.6406C39.9197 24.1022 38.1253 21.9157 37.0657 19.3576C36.0061 16.7994 35.7288 13.9845 36.269 11.2687C36.8092 8.55301 38.1426 6.05845 40.1005 4.10051C42.0584 2.14258 44.553 0.809205 47.2687 0.269012C49.9845 -0.271181 52.7994 0.00606596 55.3576 1.06569C57.9157 2.12532 60.1022 3.91974 61.6406 6.22202C63.1789 8.52431 64 11.2311 64 14L64.064 16C64.064 19.677 63.3398 23.318 61.9326 26.7151C60.5255 30.1123 58.463 33.199 55.863 35.799C53.263 38.399 50.1763 40.4615 46.7791 41.8686C43.382 43.2758 39.741 44 36.064 44V36C38.6916 36.0071 41.2945 35.4932 43.7223 34.488C46.15 33.4829 48.3544 32.0064 50.208 30.144C50.9285 29.4251 51.5931 28.6521 52.196 27.832C51.4695 27.9453 50.7353 28.0028 50 28.004V28ZM14 28C11.2311 28 8.52431 27.1789 6.22202 25.6406C3.91973 24.1022 2.12532 21.9157 1.06569 19.3576C0.00606608 16.7994 -0.271181 13.9845 0.269012 11.2687C0.809205 8.55301 2.14258 6.05845 4.10051 4.10051C6.05845 2.14258 8.55301 0.809205 11.2687 0.269012C13.9845 -0.271181 16.7994 0.00606596 19.3576 1.06569C21.9157 2.12532 24.1022 3.91974 25.6406 6.22202C27.1789 8.52431 28 11.2311 28 14L28.064 16C28.064 23.4261 25.114 30.548 19.863 35.799C14.612 41.05 7.49007 44 0.0640063 44V36C2.6916 36.0071 5.29452 35.4932 7.72227 34.488C10.15 33.4829 12.3544 32.0064 14.208 30.144C14.9285 29.4251 15.5931 28.6521 16.196 27.832C15.4695 27.9453 14.7353 28.0028 14 28.004V28Z"
        fill="#9191D6"
      />
    </svg>
  );
};

export default QuotesIcon;
