import React from 'react';

const IconArrowRight = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.56224 0.963674C8.47834 1.04729 8.41177 1.14664 8.36634 1.25604C8.32092 1.36543 8.29753 1.48272 8.29753 1.60117C8.29753 1.71963 8.32092 1.83691 8.36634 1.94631C8.41177 2.05571 8.47834 2.15506 8.56224 2.23867L12.424 6.10042L1.39899 6.10042C1.1603 6.10042 0.931382 6.19524 0.762599 6.36403C0.593816 6.53281 0.498995 6.76173 0.498995 7.00042C0.498995 7.23912 0.593816 7.46804 0.762599 7.63682C0.931381 7.8056 1.1603 7.90042 1.39899 7.90042L12.424 7.90042L8.56224 11.7637C8.39317 11.9327 8.29818 12.1621 8.29818 12.4012C8.29818 12.6403 8.39317 12.8696 8.56224 13.0387C8.73132 13.2077 8.96063 13.3027 9.19974 13.3027C9.43885 13.3027 9.66817 13.2077 9.83724 13.0387L15.2372 7.63867C15.3211 7.55506 15.3877 7.45571 15.4331 7.34631C15.4786 7.23691 15.502 7.11963 15.502 7.00117C15.502 6.88272 15.4786 6.76543 15.4331 6.65604C15.3877 6.54664 15.3211 6.44729 15.2372 6.36367L9.83724 0.963674C9.75363 0.87977 9.65428 0.813197 9.54488 0.767771C9.43548 0.722347 9.3182 0.698963 9.19974 0.698963C9.08129 0.698963 8.964 0.722347 8.85461 0.767771C8.74521 0.813197 8.64586 0.87977 8.56224 0.963674Z"
        fill="#FCF9F4"
      />
    </svg>
  );
};

export default IconArrowRight;
