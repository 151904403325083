import React from 'react';

const IconSearchDesktop = ({ color = '#FDFDFD' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.0002 19L14.6572 14.657M14.6572 14.657C15.4001 13.9141 15.9894 13.0322 16.3914 12.0615C16.7935 11.0909 17.0004 10.0506 17.0004 8.99999C17.0004 7.94939 16.7935 6.90908 16.3914 5.93845C15.9894 4.96782 15.4001 4.08588 14.6572 3.34299C13.9143 2.6001 13.0324 2.01081 12.0618 1.60877C11.0911 1.20672 10.0508 0.999786 9.00021 0.999786C7.9496 0.999786 6.90929 1.20672 5.93866 1.60877C4.96803 2.01081 4.08609 2.6001 3.34321 3.34299C1.84288 4.84332 1 6.87821 1 8.99999C1 11.1218 1.84288 13.1567 3.34321 14.657C4.84354 16.1573 6.87842 17.0002 9.00021 17.0002C11.122 17.0002 13.1569 16.1573 14.6572 14.657Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};

export default IconSearchDesktop;
