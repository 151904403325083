import React from 'react';

import css from './IconGroup.module.css';

const IconGroup = () => {
  return (
    <svg
      className={css.root}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28.2171" cy="11.5354" r="2.56669" stroke="black" strokeWidth="2.5" fill="none" />
      <path
        d="M22.7305 26.0859V20.8379C22.7305 18.691 24.0663 17.2598 28.4555 17.2598C32.8447 17.2598 33.7034 18.691 33.7034 20.8379V26.0859"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
        fill="none"
      />
      <circle cx="6.78349" cy="11.4866" r="2.56669" stroke="black" strokeWidth="2.5" fill="none" />
      <path
        d="M1.29688 26.037V20.7891C1.29688 18.6422 2.63272 17.2109 7.02191 17.2109C11.4111 17.2109 12.2699 18.6422 12.2699 20.7891V26.037"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
        fill="none"
      />
      <circle cx="17.6966" cy="10.6517" r="3.27086" stroke="black" strokeWidth="3" fill="none" />
      <path
        d="M10.8379 28.8412V22.2813C10.8379 19.5977 12.5077 17.8086 17.9942 17.8086C23.4807 17.8086 24.5541 19.5977 24.5541 22.2813V28.8412"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        fill="none"
      />
    </svg>
  );
};

export default IconGroup;
