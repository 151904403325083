import React from 'react';

import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionCategories.module.css';

const SectionCategories = ({ blocks, title, description }) => {
  return (
    <>
      <div className={css.headline}>
        <h1 className={css.titleCategories}>{title}</h1>
        <p className={css.descriptionCategories}>{description}</p>
      </div>
      <div className={css.root}>
        {blocks.map(({ icon, link, title, description }) => (
          <div className={css.block} key={title}>
            <div className={css.iconWrapper}>{icon}</div>
            <h3 className={css.title}>{title}</h3>
            <p className={css.description}>{description}</p>
            <NamedLink name={link} className={css.link}>
              <FormattedMessage id="ShopProductsAndResources.learnMore" />
            </NamedLink>
          </div>
        ))}
      </div>
    </>
  );
};

export default SectionCategories;
