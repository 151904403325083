import React from 'react';

const IconArrowLeft = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.43776 0.963674C7.52166 1.04729 7.58823 1.14664 7.63366 1.25604C7.67908 1.36543 7.70247 1.48272 7.70247 1.60117C7.70247 1.71963 7.67908 1.83691 7.63366 1.94631C7.58823 2.05571 7.52166 2.15506 7.43776 2.23867L3.57601 6.10042L14.601 6.10042C14.8397 6.10042 15.0686 6.19524 15.2374 6.36403C15.4062 6.53281 15.501 6.76173 15.501 7.00042C15.501 7.23912 15.4062 7.46804 15.2374 7.63682C15.0686 7.8056 14.8397 7.90042 14.601 7.90042L3.57601 7.90042L7.43776 11.7637C7.60683 11.9327 7.70182 12.1621 7.70182 12.4012C7.70182 12.6403 7.60683 12.8696 7.43776 13.0387C7.26868 13.2077 7.03937 13.3027 6.80026 13.3027C6.56115 13.3027 6.33183 13.2077 6.16276 13.0387L0.762756 7.63867C0.678852 7.55506 0.612279 7.45571 0.566854 7.34631C0.52143 7.23691 0.498047 7.11963 0.498047 7.00117C0.498047 6.88272 0.521429 6.76543 0.566854 6.65604C0.612279 6.54664 0.678852 6.44729 0.762756 6.36367L6.16276 0.963674C6.24637 0.87977 6.34572 0.813197 6.45512 0.767771C6.56452 0.722347 6.6818 0.698963 6.80026 0.698963C6.91871 0.698963 7.036 0.722347 7.14539 0.767771C7.25479 0.813197 7.35414 0.87977 7.43776 0.963674Z"
        fill="#FCF9F4"
      />
    </svg>
  );
};

export default IconArrowLeft;
