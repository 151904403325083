import React from 'react';

import css from './IconFilters.module.css';

const IconFilters = () => {
  return (
    <svg
      className={css.root}
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 0C0.551088 0 0.360322 0.0790175 0.21967 0.21967C0.0790175 0.360322 0 0.551088 0 0.75C0 0.948912 0.0790175 1.13968 0.21967 1.28033C0.360322 1.42098 0.551088 1.5 0.75 1.5H19.25C19.4489 1.5 19.6397 1.42098 19.7803 1.28033C19.921 1.13968 20 0.948912 20 0.75C20 0.551088 19.921 0.360322 19.7803 0.21967C19.6397 0.0790175 19.4489 0 19.25 0H0.75ZM4 5.75C4 5.55109 4.07902 5.36032 4.21967 5.21967C4.36032 5.07902 4.55109 5 4.75 5H15.25C15.4489 5 15.6397 5.07902 15.7803 5.21967C15.921 5.36032 16 5.55109 16 5.75C16 5.94891 15.921 6.13968 15.7803 6.28033C15.6397 6.42098 15.4489 6.5 15.25 6.5H4.75C4.55109 6.5 4.36032 6.42098 4.21967 6.28033C4.07902 6.13968 4 5.94891 4 5.75ZM8 10.688C8 10.4891 8.07902 10.2983 8.21967 10.1577C8.36032 10.017 8.55109 9.938 8.75 9.938H11.25C11.4489 9.938 11.6397 10.017 11.7803 10.1577C11.921 10.2983 12 10.4891 12 10.688C12 10.8869 11.921 11.0777 11.7803 11.2183C11.6397 11.359 11.4489 11.438 11.25 11.438H8.75C8.55109 11.438 8.36032 11.359 8.21967 11.2183C8.07902 11.0777 8 10.8869 8 10.688Z"
        fill="black"
      />
    </svg>
  );
};

export default IconFilters;
